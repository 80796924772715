$font-family-base: "Usual", /* "Usual Regular",*/ "Usual Bold", "Roboto", "Helvetica Neue" !default;

html,
body {
	// min-height: 100% !important;
	font-family: $font-family-base;
}

body {
	margin: 0;
}

@font-face {
	font-family: "Usual";
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/Regular.woff') format("woff");
}

@font-face {
	font-family: "Usual Regular";
	font-style: normal;
	font-weight: normal;
	src: url('/fonts/Regular.woff') format("woff");
}

@font-face {
	font-family: "Usual Italic";
	font-style: normal;
	font-weight: normal;
	src: local("Usual Italic"), url("/fonts/Italic.woff") format("woff");
}

@font-face {
	font-family: "Usual Light Italic";
	font-style: normal;
	font-weight: normal;
	src: local("Usual Light Italic"),
		url("/fonts/Light Italic.woff") format("woff");
}

@font-face {
	font-family: "Usual Light";
	font-style: normal;
	font-weight: normal;
	src: local("Usual Light"), url("/fonts/Light.woff") format("woff");
}

@font-face {
	font-family: "Usual Medium Italic";
	font-style: normal;
	font-weight: normal;
	src: local("Usual Medium Italic"),
		url("/fonts/Medium Italic.woff") format("woff");
}

@font-face {
	font-family: "Usual Medium";
	font-style: normal;
	font-weight: normal;
	src: local("Usual Medium"), url("/fonts/Medium.woff") format("woff");
}

@font-face {
	font-family: "Usual Bold Italic";
	font-style: normal;
	font-weight: normal;
	src: local("Usual Bold Italic"),
		url("/fonts/Bold Italic.woff") format("woff");
}

@font-face {
	font-family: "Usual Bold";
	font-style: normal;
	font-weight: normal;
	src: local("Usual Bold"), url("/fonts/Bold.woff") format("woff");
}

@font-face {
	font-family: "Usual ExtraBold Italic";
	font-style: normal;
	font-weight: normal;
	src: local("Usual ExtraBold Italic"),
		url("/fonts/ExtraBold Italic.woff") format("woff");
}

@font-face {
	font-family: "Usual ExtraBold";
	font-style: normal;
	font-weight: normal;
	src: local("Usual ExtraBold"), url("/fonts/ExtraBold.woff") format("woff");
}
