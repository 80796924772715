.callToClick {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  > p {
    font: normal normal normal 16px/19px Usual Regular;
    letter-spacing: 0px;
    // color: #535353;
  }
}

.specificTable {
  display: flex;
  justify-content: center;
  align-items: center;
  // overflow: auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
