.Container {

  // background-color: #F7F8FA; // ${(props) => (props.theme.isDark ? '#131A20' : '#F7F8FA')};
  display: flex;
  flex: 1;
  // flex-direction: row;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.SideBoxYield {
  width: 100%;
  max-width: 350px;
  padding: 10px;
}

.BoxYield {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff; // dark => '#1D262F'
  border: 1px solid #ebebeb; // dark => '#131A20'
  padding: 8px 4px;
  border-radius: 4px;
}

.BoxYieldTitle { // text
  font-size: 13px;
  // color: #888; // dark => '#ccc'
}

.BoxYieldAreaValue {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.BoxYieldValue { // text
  font-size: 14px;
  font-weight: bold;
  // color: #535353; // dark => '#fff'
  margin-top: 6px;
}
