.ScrollView {
  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.BoxData {
  flex: 1;
  border-width: 1px;
  border-color: #ccc; // ${(props) => (props.theme.isDark ? '#1D262F' : '#ccc')};
  border-radius: 6px;
  padding: 10px 14px;
  margin: 12px;

  max-width: 450px;
}

.BoxDataHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.BoxDataLogo {
  // image
  // width: 40px;
  height: 40px;
}

.BoxDataAreaName {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.BoxDataName {
  // text
  font-size: 16px;
  font-weight: bold;
  // color: #535353; dark => #ccc
  margin-left: 12px;
}

// .BoxDataBtnMenu { // button
//   width: 24px;
//   height: 24px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.BoxDataBody {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 8px;
}

.BoxDataBodySide {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.BoxDataBodySideRow {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.BoxDataBodyTitle {
  // text
  display: flex;
  flex: 1;
  font-size: 12px;
  // color: #535353; // dark => #fff
}

.BoxDataBodyValue {
  // text
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  // color: #535353; // dark => #fff
  margin-top: 4px;
}
