.ContainerPartnerButton {
  flex: 1;
  position: relative;

  cursor: pointer;
  margin-top: 5px;
  // margin-bottom: 0px;
  max-width: 1000px;
  margin: 0 auto;
}

.PartnerButton { // button
  flex: 1;
  margin-top: 10px;
  // height: 100px;
  border-radius: 8px;
  // background-color: #fff;
}