.partnerCompIframe {
  width: 100%;
  height: 100px;
  pointer-events: none;
  overflow-y: hidden;

  @media only screen and (max-width: 1630px) {
    height: 100px;
  }

  @media only screen and (max-width: 1370px) {
    height: 100px;
  }
  @media only screen and (max-width: 1230px) {
    height: 100px;
  }
}
