.Container {
  flex: 1;
  // background-color: #fff; // ${(props) => (props.theme.isDark ? '#131A20' : '#fff')};
}

.Content { // scroll view
  flex: 1;
  // background-color: #fff; // ${(props) => (props.theme.isDark ? '#131A20' : '#fff')};
  padding: 0 10px;
  overflow-y: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.AreaFilters {
  margin: 10px;
  align-self: center;
  padding-bottom: 10px;
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  border-color: #ccc; // ${(props) => (props.theme.isDark ? '#777' : '#ccc')};
  margin-bottom: 10px;
}

// .InputContainerStyle {
//   margin: 4px;
//   padding: 0;
//   color: red;
//   height: 44px;
//   border-radius: 12px;
// }

// .PickerStyle { // text
//   color: #444;
//   font-weight: bold;
//   height: 44px;
//   border-radius: 12px;
// }

// .MounthTitle { // text
//   font-size: 18px;
//   font-weight: bold;
//   color: #535353; // ${(props) => (props.theme.isDark ? '#ccc' : '#535353')};
//   align-self: center;
//   margin: 12px 0;
// }

// .BoxDataPayment {
//   width: 99%;
//   padding: 8px;
//   align-items: center;
//   justify-content: center;
//   /* border-width: 1px;
//   border-color: ${(props) => (props.theme.isDark ? '#777' : '#ccc')};
//   background-color: ${(props) =>
//     props.theme.isDark ? '#1D262F' : '#F7F8FA'}; */
//   border-radius: 12px;
//   align-self: center;
//   margin-bottom: 10px;
//   margin-top: 12px;
// }

// .TextBoxDataPayment { // text
//   color: #535353; // ${(props) => (props.theme.isDark ? '#ccc' : '#535353')};
//   font-size: 15px;
//   font-weight: bold;
// }

// .HeaderDataTable {
//   border-width: 1px;
//   border-color: #ccc; // ${(props) => (props.theme.isDark ? '#777' : '#ccc')};
//   border-radius: 12px;
//   height: 34px;
//   padding: 0;
// }

// .TitleHeaderDataTable { // text
//   color: #535353; // ${(props) => (props.theme.isDark ? '#f4f4f4' : '#535353')};
//   padding: 0;
//   align-items: center;
//   justify-content: center;
//   font-size: 13px;
// }

// .CellTable {
//   align-items: center;
//   justify-content: center;
// }

// .TextCellTable { // text
//   font-size: 12px;
//   font-weight: bold;
//   text-align: center;
//   color: #535353; // ${(props) => (props.theme.isDark ? '#ccc' : '#535353')};
// }

// .ImageCountry { // image
//   width: 32px;
//   height: 32px;
//   margin-right: 4px;
//   margin-left: -4px;
// }

.DatePickerContainer {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.DatePickerLabel { // text
  font-weight: bold;
  font-size: 14px;
  align-self: center;
  margin: 12px 5px;
  color: #535353; // ${(props) => (props.theme.isDark ? '#ccc' : '#535353')};
}

// .AreaAutocomplete {
//   position: absolute;
//   width: 160px;
//   left: 38px;
//   align-items: center;
//   min-height: 54px;
//   justify-content: center;
//   z-index: 9;
// }

// .AutocompleteContainer {
//   position: relative;
//   flex-direction: row;
//   align-items: center;
//   border-radius: 4px;
//   margin: 0 10px;
// }
