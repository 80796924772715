.upCommingDividendsView {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  // height: calc(100vh - 150px);
  height: 100%;
  > h1 {
    font-size: 24px;
    font-family: "Usual Bold";
    letter-spacing: -0.24px;
    // color: #535353;
    margin-bottom: 0;
    margin-left: 40px;
  }
}

.gridContainer {
  height: 100%;
  display: flex;
  justify-content: center;
}

.upCommingDividendsContent {
  padding: 10px 10px;
  // margin-right: 100px;
  padding-bottom: 0px;

  // @media only screen and (max-width: 700px) {
  //   width: 100%
  // }

}

.dividendsResults {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;
  padding: 0 10px;
  margin-bottom: 0px;


  > h2 {
    font: normal normal 800 20px/24px Usual Bold;
    letter-spacing: 0px;
    // color: #535353;
    margin: 0;
    margin-bottom: 20px;
  }
}

.dividendsResultsContainer {
  // overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-right: 10px;
  > div {
    width: 100%;
    margin: 0 auto;
    // border: 1px solid #dcdcdc;
    // border-radius: 9px;
    margin-bottom: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      width: 100%;

      margin-bottom: 10px;

      text-align: center;

      background-color: #f7f8fa;
      border: 1px solid #dcdcdc;
      border-radius: 9px;
    }
    h3 {
      font: normal normal 800 20px/24px Usual Bold;
      letter-spacing: 0px;
      // color: #535353;
      margin: 10px 0;
    }
  }
}

.dividendsResultsContent {
  width: 100%;

  margin: 0 auto;
  margin-bottom: 35px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    width: 100%;

    text-align: center;

    background-color: #f7f8fa;
    border: 1px solid #dcdcdc;
    border-radius: 9px;
  }
  h3 {
    font: normal normal 800 20px/24px Usual Bold;
    letter-spacing: 0px;
    // color: #535353;
    margin: 10px 0;
  }
}

.seeMoreContainer {
  background-color: rgba(247, 248, 250, 0.48);
  padding: 30px 35px;
  box-shadow: -5px 2px 5px #00000029;
  border-radius: 0px 11px 11px 0px;
}

.callToClick {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  > p {
    font: normal normal normal 16px/19px Usual Regular;
    letter-spacing: 0px;
    // color: #535353;
  }
}

.specificTable {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  margin-top: 40px;
}

.ads-container{
  max-width: 100px;
  height: 100%;
  border: 1px solid #ebebeb;
  margin-top: 30px;
}