.DialogComponent {
  flex: 1;
  background-color: #f7f8fa;
}

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    margin: 0;
    width: 377px;
    height: 90vh;
    margin-bottom: 50px;
    background-color: #fff;
}
}

.dialogCloseIcon {
  float: right;
}

.BtnSubscribeNow {
  // button
  border-radius: 20px;
  border-width: 0px;
  width: 80%;
  max-width: 360px;
  height: 44px;
  background-color: #01cc7f;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 3px #ccc;
  align-self: center;
  position: absolute;
  bottom: 20px;

  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.BtnSubscribeNowText {
  // text
  font-weight: bold;
  font-size: 16px;
  color: #fff;

}

.BtnSubscribeNow:hover {
  color: #fff;
  border-radius: 10px;
  height: 50px;
}